import './PhotoGalleryStyle.css'
import { HouseInfo } from '../Data/HomePhotos/HouseInfo'
import { useState } from 'react'
function PhotoGallery() {
    const [FilterdHouseInfo, setHouseInfo] = useState(HouseInfo)
    const [House, setHouse] = useState({})
    const [isHovered, setHovered] = useState(false)
    const [filter, setFilter] = useState("")
    const handleHover = (e) => {
        const key = e.target.name
        if(House===key) {
            setHovered(!isHovered)
        }
        else{
            setHovered(true)
        }
        setHouse(e.target.name)

    }

    const handleFilter = (e) => {
        e.preventDefault()
        setFilter(e.target.value)
        let lFilter = e.target.value.toLowerCase()
        let filteredList = []
        HouseInfo.map((house) => {
            if (house.name.toLowerCase().includes(lFilter)) {
                filteredList.push(house)
            }
        })
        setHouseInfo(filteredList)
    }
    return (<>
        <div className='customplans'>
            <p>We offer many different house design. Below we have many Predesigned plans for you to view. We also offer custom designed plans or you can visit Hearthstone designs for more beautiful designs.</p>
            <a href="https://www.hearthstonedesign.com/" target="_blank">Visit Hearthstone Designs</a>
        </div>
        <div className="Filter">
            <br></br>
            <label>Filter by Plan Name<input name="filter" value={filter || ""} onChange={handleFilter} placeholder="Filter by"></input></label>
            {filter !== "" && <>
                <h1>Filtering on {filter}</h1>
            </>}
        </div>
        <div className="PhotoGallery">
            {FilterdHouseInfo.map((house) =>
                <div className='Photo' key={house.name}>
                    <h2 name={house.name}>{house.name}</h2>
                    <img src={house.image} name={house.name} onClick={handleHover} alt={house.name}/>
                    {isHovered && (house.name === House) &&
                        <div id="extraInfo" >
                            <h1>Style: {house.style}</h1>
                            <h1>Beds: {house.beds}</h1>
                            <h1>Baths: {house.baths}</h1>
                            <h1>Sq. Feet: {house.sqft}</h1>
                            <h1>Garage: {house.garage}</h1>
                        </div>}
                </div>
            )}
        </div>
    </>
    )
}

export default PhotoGallery  