
import AccentWall from './AccentWall.jpg'
import BarnDoors from './BarnDoors.jpg'
import BasementStair from './BasementStair.jpg'
import BasementStair2 from './BasementStair2.jpg'
import BathroomSink from './BathroomSink.jpg'
import BathTub from './BathTub.jpg'
import BathTub2 from './BathTub2.jpg'
import Ceilingwithlight from './Ceilingwithlight.jpg'
import Closet from './Closet.jpg'
import Closet2 from './Closet2.jpg'
import EntertaimentCenter from './EntertainmentCenter.jpg'
import Entryway from './Entryway.jpg'
import Kitchen from './Kitchen.jpg'
import Kitchen2 from './Kitchen2.jpg'
import Kitchen3 from './Kitchen3.jpg'
import Kitchen4 from './Kitchen4.jpg'
import KitchenShelves from './KitchenShelves.jpg'
import LaundryRoom from './LaundryRoom.jpg'
import Molding from './Molding.jpg'
import Pantry from './Pantry.jpg'
import Pantry2 from './Pantry2.jpg'
import Shelves from './Shelves.jpg'
import Shower from './Shower.jpg'
import Shower2 from './Shower2.jpg'
import StairAndRailing from './StairAndRailing.jpg'
import StairAndRailing2 from './StairAndRailing2.jpg'
import StairAndRailing3 from './StairAndRailing3.jpg'
import StairAndRailing4 from './StairAndRailing4.jpg'
import WallCorner from './WallCorner.jpg'
import WoodenWall from './WoodenWall.jpg'
import WoodStove from './WoodStove.jpg'
import FirePlace from './FirePlace.jpg'
const InteriorArray = [WoodStove,

    WallCorner,
    StairAndRailing,
    StairAndRailing2,
    StairAndRailing4,
    StairAndRailing3,
    BasementStair2,
    Shower,
    Shower2,
    Shelves,
    WoodenWall,
    Pantry,
    Pantry2,
    Molding,
    LaundryRoom,
    Kitchen,
    Kitchen2,
    Kitchen3,
    Kitchen4,
    KitchenShelves,
    Entryway,
    EntertaimentCenter,
    Closet,
    Closet2,
    Ceilingwithlight,
    BathTub,
    BathTub2,
    BathroomSink,
    BasementStair,
   
    BarnDoors,
    AccentWall,
    FirePlace]
export {
    WoodStove,
    WoodenWall,
    WallCorner,
    StairAndRailing,
    StairAndRailing2,
    StairAndRailing3,
    StairAndRailing4,
    Shower,
    Shower2,
    Shelves,
    Pantry,
    Pantry2,
    Molding,
    LaundryRoom,
    Kitchen,
    Kitchen2,
    Kitchen3,
    Kitchen4,
    KitchenShelves,
    Entryway,
    EntertaimentCenter,
    Closet,
    Closet2,
    Ceilingwithlight,
    BathTub,
    BathTub2,
    BathroomSink,
    BasementStair,
    BasementStair2,
    BarnDoors,
    AccentWall,
    FirePlace,
    InteriorArray
}