import BailyFarmsOffice from './BailyFarmsOfficeCompleteOutside.jpg'
import BailyFarmsOfficeCropped from './BailyFarmsOfficeCompleteOutsideCropped.jpg'
import BrownStoneHome from './BrownStoneHomeCompleteOutside.jpg'
import EyeCenterOfEphraimOffice from './EyeCenterOfEphraimCompleteOutside.jpg'
import RedBrickHouse from './RedBrickHouseCompleteOutside.jpg'
import SubwayComplete1 from './SubwayCompleteOutside1.jpg'
import SubwayComplete2 from './SubwayCompleteOutside2.jpg'
import WhiteHouseCompleteOutside from './WhiteHouseCompleteOutside.jpg'
import RedBrickHouseCompleteOutsideCropped from './RedBrickHouseCompleteOutsideCropped.jpg'

const completedarray = [BailyFarmsOffice,BrownStoneHome,EyeCenterOfEphraimOffice,RedBrickHouse,SubwayComplete1,SubwayComplete2,WhiteHouseCompleteOutside]
export{
    BailyFarmsOffice,
    BrownStoneHome,
    EyeCenterOfEphraimOffice,
    RedBrickHouse ,
    SubwayComplete1,
    SubwayComplete2,
    WhiteHouseCompleteOutside,
    completedarray,
    BailyFarmsOfficeCropped,
    RedBrickHouseCompleteOutsideCropped
}