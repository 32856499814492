import framedHouse1 from './61369617789__96959DEE-587A-477C-B586-8918685ECFEA.JPG'
import framedhouse2 from './63829155602__3FEE6018-5CBD-4260-9777-AE7572EAFB72.jpg'
import framedHouse3 from './61369619672__CEE9C433-D3CB-4157-8E20-32C229C85A69.JPG'
import domehouse from './dome house.jpg'
import elkins from './elkins.jpg'
import rezisedr from './Resized_R.jpg'
import resizeds from './Resized_S.jpg'
import snapchat from './Snapchat-.jpg'

const FrammingArray = [framedHouse1,framedHouse3,framedhouse2,elkins,domehouse,resizeds,rezisedr,snapchat]

export {FrammingArray}