import {
    Amberwoods,
    Amherst,
    Auburn,
    Aurora,
    AutumnManor,
    Avalon,
    Barcelona,
    Brentwood,
    BridgePort,
    Brighton,
    Brisbane,
    Bristol,
    Brittany,
    CasaGrande,
    Charleston,
    Claremont,
    Coronado,
    Elkmont,
    Fairbrook,
    FalconRidge,
    Foxwoods,
    Harmony,
    HuntingtonPark,
    Isabella,
    Lexington,
    MapleVillage,
    Meadowbrook,
    MesaVerde,
    Monterey,
    Nantuckett,
    Normandy,
    OakHaven,
    OrangeBlossom,
    Pollyanna,
    Providence,
    Quincy,
    Rockford,
    SantaClara,
    Savannah,
    Sheffield,
    SkipperBay,
    Somerset,
    SteepleChase,
    Stonebridge,
    Summit,
    Tiffany,
    TimberRidge,
    Wellington,
    WillowBay,
    WindhamHill,
    Winterhaven,
    WintersetManer
} from './HomePhotos'
// //region
const HouseInfo = [{
    "name": "Amberwoods",
    "image": Amberwoods,
    "style": "Multi-Unit",
    "sqft": 1100,
    "beds": 2,
    "baths": 1.5,
    "garage": "None"
},
{
    "name": "Amherst",
    "image": Amherst,
    "style": "Rambler",
    "sqft": 2176,
    "beds": 3,
    "baths": 2,
    "garage": "3 Car"
},
{
    "name": "Auburn",
    "image": Auburn,
    "style": "Rambler",
    "sqft": 1659,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Aurora",
    "image": Aurora,
    "style": "Rambler",
    "sqft": 1858,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Autumn Manor",
    "image": AutumnManor,
    "style": "2 Story",
    "sqft": 4395,
    "beds": 3,
    "baths": 2.5,
    "garage": "3 Car"
},
{
    "name": "Avalon",
    "image": Avalon,
    "style": "Rambler",
    "sqft": 2140,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},{
    "name": "Barcelona",
    "image": Barcelona,
    "style": "Rambler",
    "sqft": 2166,
    "beds": 4,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Brentwood",
    "image": Brentwood,
    "style": "Rambler",
    "sqft": 1160,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Bridge Port",
    "image": BridgePort,
    "style": "2 Story",
    "sqft": 2175,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Brighton",
    "image": Brighton,
    "style": "Rambler",
    "sqft": 2197,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Brisbane",
    "image": Brisbane,
    "style": "Rambler",
    "sqft": 1618,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Bristol",
    "image": Bristol,
    "style": "Rambler",
    "sqft": 1115,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},{
    "name": "Brittany",
    "image": Brittany,
    "style": "Rambler",
    "sqft": 1886,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Casa Grande",
    "image": CasaGrande,
    "style": "2 Story",
    "sqft": 3480,
    "beds": 5-6,
    "baths": 3-4,
    "garage": "3 Car"
},
{
    "name": "Charleston",
    "image": Charleston,
    "style": "2 Story",
    "sqft": 3056,
    "beds": 5,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Claremont",
    "image": Claremont,
    "style": "Rambler",
    "sqft": 1757,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Coronado",
    "image": Coronado,
    "style": "Rambler",
    "sqft": 2575,
    "beds": 3,
    "baths": 2,
    "garage": "3 Car"
},
{
    "name": "Elkmont",
    "image": Elkmont,
    "style": "Rambler",
    "sqft": 1865,
    "beds": 2,
    "baths": 2,
    "garage": "2 Car"
},{
    "name": "Fairbrook",
    "image": Fairbrook,
    "style": "Rambler",
    "sqft": 1798,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Falcon Ridge",
    "image": FalconRidge,
    "style": "Rambler",
    "sqft": 1595,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Foxwoods",
    "image": Foxwoods,
    "style": "2 Story",
    "sqft": 1830,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Harmony",
    "image": Harmony,
    "style": "2 Story",
    "sqft": 2280,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Huntington Park",
    "image": HuntingtonPark,
    "style": "Rambler",
    "sqft": 2175,
    "beds": 2,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Isabella",
    "image": Isabella,
    "style": "Rambler",
    "sqft": 1698,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},{
    "name": "Lexington",
    "image": Lexington,
    "style": "Rambler",
    "sqft": 1288,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Maple Village",
    "image": MapleVillage,
    "style": "Multi-Unit",
    "sqft": "1000 Each",
    "beds": 2,
    "baths": 1,
    "garage": "None"
},
{
    "name": "Meadowbrook",
    "image": Meadowbrook,
    "style": "Split Level",
    "sqft": 1409,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Mesa Verde",
    "image": MesaVerde,
    "style": "Rambler",
    "sqft": 1405,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Monterey",
    "image": Monterey,
    "style": "Split Level",
    "sqft": 2354,
    "beds": 2,
    "baths": 2.5,
    "garage": "3 Car"
},
{
    "name": "Nantuckett",
    "image": Nantuckett,
    "style": "Rambler",
    "sqft": 1342,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},{
    "name": "Normandy",
    "image": Normandy,
    "style": "Rambler",
    "sqft": 1851,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Oak Haven",
    "image": OakHaven,
    "style": "Rambler",
    "sqft": 1560,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Orange Blossom",
    "image": OrangeBlossom,
    "style": "Multi-Unit",
    "sqft": "1215 Each",
    "beds": 4,
    "baths": 3,
    "garage": "2 Car"
},
{
    "name": "Pollyanna",
    "image": Pollyanna,
    "style": "Rambler",
    "sqft": 1395,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Providence",
    "image": Providence,
    "style": "2 Story",
    "sqft": 2850,
    "beds": 4,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Quincy",
    "image": Quincy,
    "style": "Rambler",
    "sqft": 1879,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},{
    "name": "Rockford",
    "image": Rockford,
    "style": "Rambler",
    "sqft": 1497,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Santa Clara",
    "image": SantaClara,
    "style": "Split-Level",
    "sqft": 1215,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
//endregion
{
    "name": "Savannah",
    "image": Savannah,
    "style": "2 Story",
    "sqft": 3860,
    "beds": 4,
    "baths": 2.5,
    "garage": "3 Car"
},
{
    "name": "Sheffield",
    "image": Sheffield,
    "style": "Rambler",
    "sqft": 2275,
    "beds": 1,
    "baths": 1.5,
    "garage": "2 Car"
},
{
    "name": "Skipper Bay",
    "image": SkipperBay,
    "style": "Rambler",
    "sqft": 1360,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Somerset",
    "image": Somerset,
    "style": "Rambler",
    "sqft": 1270,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},{
    "name": "Steeple Chase",
    "image": SteepleChase,
    "style": "Rambler",
    "sqft": 1814,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Stonebridge",
    "image": Stonebridge,
    "style": "Rambler",
    "sqft": 2198,
    "beds": 3,
    "baths": 2,
    "garage": "3 Car"
},
{
    "name": "Summit",
    "image": Summit,
    "style": "Rambler",
    "sqft": 1545,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Tiffany",
    "image": Tiffany,
    "style": "2 Story",
    "sqft": 2068,
    "beds": 5,
    "baths": 3,
    "garage": "2 Car"
},
{
    "name": "Timber Ridge",
    "image": TimberRidge,
    "style": "Rambler",
    "sqft": 1408,
    "beds": 3,
    "baths": 2,
    "garage": "2 Car"
},
{
    "name": "Wellington",
    "image": Wellington,
    "style": "Rambler",
    "sqft": 1375,
    "beds": 2,
    "baths": 2,
    "garage": "2 Car"
},{
    "name": "Willow Bay",
    "image": WillowBay,
    "style": "2 Story",
    "sqft": 2136,
    "beds":3,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Windham Hill",
    "image": WindhamHill,
    "style": "2 Story",
    "sqft": 1871,
    "beds": 4,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Winterhaven",
    "image": Winterhaven,
    "style": "Rambler",
    "sqft": 1614,
    "beds": 3,
    "baths": 2.5,
    "garage": "2 Car"
},
{
    "name": "Winterset Manor",
    "image": WintersetManer,
    "style": "2 Story",
    "sqft": 2308,
    "beds": 4,
    "baths": 2.5,
    "garage": "2 Car"
}]
export {HouseInfo};