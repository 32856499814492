import { useEffect, useState } from "react"
import './MovingQuoteStyle.css'

function MovingQuote(params) {
    const [quote, setQuote] = useState(params.quotes.length > 0 ? params.quotes[0] : "No Slogan to show")
    const [quotes, setQuotes] = useState(params.quotes.length > 0 ? params.quotes : null)
    const [quoteState, setQuoteState] = useState(0)
    const [style, setStyle] = useState({ opacity: 0 })
useEffect(() => {
    const timer = setTimeout(() => {
        if (quoteState === 5) {
            setQuoteState(0)
            setStyle({ opacity: 0 })
            if (quotes.indexOf(quote) === quotes.length - 1)
                setQuote(quotes[0])
            else {
                setQuote(quotes[quotes.indexOf(quote) + 1])
            }
        }
        else {
            setQuoteState(quoteState + 1)
            if (quoteState + 1 === 1)
                setStyle({ opacity: 1 })
            else if (quoteState + 1 === 5)
                setStyle({ opacity: 0 })
        }

    }, 1000);

    return () => clearTimeout(timer);

}, [quoteState])
return (
    <div className="MovingQuote" style={style}>
        <h1>{quote}</h1>
    </div>
)
}

export default MovingQuote