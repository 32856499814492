
import logo from '../Data/toddalderlogo.jpg';
import MenuList from '../MenuList/MenuList';
import './MenuStyle.css'

function Menu(params) {


    return (
        <div className="Menu">
                <img src={logo} alt="Todd Alder Construction Logo" onClick={()=>params.parentCallBack("/")}></img>
                <MenuList parentCallBack={params.parentCallBack}></MenuList>
        </div>
    );
};
export default Menu;