import './BidsBannerStyle.css'
import LiftingTruses from "../../Data/ConstructionPhotos/Lifting Trusses.jpg"
function ServiceOne(params) {
    return (
        <div className="BannerBid">
            <div className="">
                <p>We are Sanpete County's number one construction company. We Love the work we do each and every day. We enjoy working with others in our communities If you are looking for a chance to work with us take a look at our bids page our contact us!</p>
            </div>
            <div className="BannerText">
                <a onClick={()=>params.parentCallBack("/Bid")}>View Bids</a>
            </div>
        </div>
    );
}

export default ServiceOne;