import { useState } from 'react';
import './AboutUsStyle.css';
import ToddAndCindyImage from '../Data/Todd and Cindy.jpg'
import ToddFamily from '../Data/ToddFamily.jpg'
import Quotes from '../Quotes/Quotes';

function AboutUs() {


    return (
        <div className='AboutUs'>
            <div className='AboutUsContent'>
                <h1>Todd Alder Construction</h1>
                <div className="AboutUsContentImgBox">
                    <img src={ToddFamily}></img>
                    <h3>
                        <p>
                            Todd Alder Construction was formed in 1994 by Charles “Todd” Alder.  Todd is a native to Sanpete County having spent his childhood in Ephraim, Utah.  Todd earned his Associates Degree in Business from Snow College and went on to earn his Bachelors degree in Business Administration from Southern Utah University.
                            Todd Alder Construction began as a small custom home builder and has grown steadily to become one of the most reputable residential and commercial builders in Central Utah.  Todd Alder Construction is known for its quality craftsmanship and now builds Single Family, Multi Family and Commercial Buildings in Utah.
                            Todd Alder Construction knows that choosing a builder is one of the most important decisions that you will make.  We will work to make sure that you, our valued customers have a successful construction experience.  We pride ourselves in our quality and workmanship.  Our goal is to exceed your expectations so completely that you will confidently recommend us to others.
                        </p>
                    </h3>
                </div>
            </div>
            {/* 
             */}

        </div>
    );
};

export default AboutUs;