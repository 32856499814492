
import { useState } from 'react'
import './ImageCollage.css'
import { click } from '@testing-library/user-event/dist/click'

function ImageCollage(params) {
    const [data] = useState(params.collection)
    const [clicked, setClicked] = useState(params.isclicked)
    const [selected, setSelected] = useState(params.selected)
    const [index, setIndex] = useState(params.index)

    function handleClick(index) {
        setClicked(!clicked)
        setIndex(index)
        setSelected(data[index])
        params.clicked(!clicked)
        params.seti(index)
        params.setS(data[index])
        params.setSelectedArray(data)
    }
    function handleSelectedChange(direction) {
        let i = index
        console.log(i)
        if (direction === "left") {
            if (i > 0)
                i = i - 1;
            else {
                i = data.length - 1;
            }
        }
        else {
            if (i == data.length - 1)
                i = 0;
            else
                i = i + 1;
        }
        console.log(i)
        setIndex(i)
        setSelected(data[i])
    }
    return (<div className='ImageCollage'>
                <h2>{params.Description}</h2>
        {!clicked && <>
            <div className='Top'>
                <img src={data[0]} onClick={() => handleClick(0)} alt=""></img>
            </div>
            <div className='Bottom'>
                {
                    data.map(
                        (image, idx) => {
                            if (idx > 0 && idx < 3) {
                                return (<img src={image} key={idx} onClick={() => handleClick(idx)} alt=""></img>)
                            }
                            if (idx == 3) {
                                return (
                                    <>
                                        <img src={image} key={idx} onClick={() => handleClick(idx)} alt=""></img>
                                        <div className='Overlay'>
                                            {"+" + String(data.length - 4)}
                                        </div>
                                    </>
                                )
                            }
                        }
                    )
                }
            </div>
        </>
        }
        {clicked && 
        <div className='LargeView'>
            <button onClick={() => handleSelectedChange("left")}className='left'>{"<"}</button>
            <img src={selected} alt=""></img>
            <button onClick={() => handleSelectedChange("right")} className='right'>{">"}</button>
            <button onClick={() => handleClick(0)} className='x'>x</button></div>}

    </div >)

}

export default ImageCollage