import { useState } from 'react';
import './ContactUs.scss';
import { isValidEmail, isValidPhone } from '../Validation/Validation';

function ContactUs() {
    const [formIn, setForm] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [toFix, setToFix] = useState([]);
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setForm(values => ({ ...values, [name]: value }))
        let temp = false
        if (formIn.PhoneNumber != undefined || formIn.PhoneNumber != NaN || formIn.PhoneNumber != "") { temp = true }
        if (formIn.Name != undefined || formIn.Name != NaN || formIn.Name != "") { temp = true }
        if (formIn.Email != undefined || formIn.Email != NaN || formIn.Email != "") { temp = true }
        if (formIn.Message != undefined || formIn.Message != NaN || formIn.Message != "") { temp = true }
        setIsValid(temp)
    }



    return (
        <div className='ContactUs'>
            <h1>Contact Todd Alder Construction</h1>
            <form >
                <input type="text" placeholder="Your Name" onChange={handleChange} name="Name" value={formIn.Name} required></input>
                <input type="text" placeholder="Phone Number" name="PhoneNumber" value={formIn.PhoneNumber} onChange={handleChange} required></input>
                <input type="text" placeholder="Email" name="Email" value={formIn.Email} onChange={handleChange} required></input>
                <textarea type="textarea" placeholder="Your Message:" name="Message" value={formIn.Message} onChange={handleChange} required></textarea>
                {isValid &&

                    <a href={`mailto:contact@toddalder.com? &body=${formIn.Message}`} >Submit</a>
                }
                {!isValid &&
                    <button disabled={true}>
                        Submit
                    </button>}

            </form>
        </div>
    )
}

export default ContactUs;