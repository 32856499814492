import a from './65480635864__A9E36E7F-D5BB-49F4-AA5C-B70991C5FB79.jpg'
import b from './65480636692__AB2212E0-2829-4E10-9E52-381CC21A3952.jpg'
import c from './68996796470__610ADA79-6A3B-4383-9509-49C8687D6815.jpg'
import d from './68996803667__501F3418-7ED8-4457-9039-6C2233682D69.jpg'
import e from './IMG_0897.JPG'
import f from './IMG_0898.JPG'
import g from './IMG_1430.JPG'
import h from './IMG_1432.JPG'
import i from './IMG_1477.JPG'
import j from './IMG_1478.JPG'
import k from './IMG_1481.JPG'
import l from './IMG_1482.JPG'
import m from './IMG_1576.JPG'
import n from './IMG_1577.JPG'
import o from './IMG_1616.JPG'
import p from './IMG_1623.JPG'
import q from './IMG_1626.JPG'
import r from './IMG_3487.jpg'
import s from './IMG_3663.jpg'
import t from './IMG_3664.jpg'
import u from './IMG_3665.jpg'
import v from './IMG_3666.jpg'
import w from './IMG_3781.jpg'
import x from './IMG_3783.jpg'
import y from './IMG_3785.jpg'

const ExcavationArray = [a,b,c,d,e,f,g,h,i,j,k,m,n,l,o,p,q,r,s,t,u,v,w,x,y]

export {
    ExcavationArray
}