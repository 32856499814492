
import './HomeGalleryStyle.css'
import { useState } from 'react';

import ImageCollage from '../imageCollage/ImageCollage';
import { completedarray } from '../Data/HomeGalleryPhotos/CompletedProjects/CompletedProjects'
import { InteriorArray } from '../Data/HomeGalleryPhotos/Interior/Interiors';
import { FrammingArray } from '../Data/ConstructionPhotos/Framing/Framing';
import { ExcavationArray } from '../Data/ConstructionPhotos/Excavation/Excavation';
import { DemoArray } from '../Data/ConstructionPhotos/Demolition/Demolition';
import { ConcreteArray } from '../Data/ConstructionPhotos/ConcreteWork/Concrete';
const f = ["None", "Construction", "Framing", "Concrete", "Interior", "Demolition", "Completed", "Excavation"]
function HomeGallery() {
    const [filter, setFilter] = useState("None")
    const [clicked ,setClicked] = useState(null)
    const[selectedArray,setSelecteArray]= useState(null)
    const [selected,setSelected]=useState(completedarray[0])
    const [index,setIndex]= useState(0)
console.log(clicked)
    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    }
    return (
        <div className='HomeGallery'  >
            {!clicked&&<>
            <div className='HomeGalleryFilter'><b>Filters</b>
            <br></br>
                <select value={filter} onChange={handleFilterChange}>
                    {f.map((fil, idx) => {
                        return <option value={fil} key={idx}>{fil}</option>
                    })}
                </select>
            </div>
            
            <div className='portfolio'>
                {(filter === "None" || filter === "Completed") &&
                    <ImageCollage collection={completedarray} Description={"Completed Projects"} className="a1" clicked = {setClicked} setS={setSelected} seti={setIndex} setSelectedArray={setSelecteArray}></ImageCollage>
                }
                {(filter === "None" || filter === "Completed" || filter === "Interior") &&
                    <ImageCollage collection={InteriorArray} Description="Completed Interiors" className="b1" clicked = {setClicked} setS={setSelected} seti={setIndex}  setSelectedArray={setSelecteArray}></ImageCollage>
                }
                {(filter === "None" || filter === "Framing" || filter === "Construction") &&
                    <ImageCollage collection={FrammingArray} Description={"Framing"} className="a2" clicked = {setClicked} setS={setSelected} seti={setIndex}  setSelectedArray={setSelecteArray}></ImageCollage>
                }
                {(filter === "None" || filter === "Excavation" || filter === "Construction") &&
                    <ImageCollage collection={ExcavationArray} Description="Excavation" className="b2" clicked = {setClicked} setS={setSelected} seti={setIndex}  setSelectedArray={setSelecteArray}></ImageCollage>
                }
                {(filter === "None" || filter === "Concrete" || filter === "Construction") &&
                    <ImageCollage collection={ConcreteArray} Description="Concrete Work" className="a3" clicked = {setClicked} setS={setSelected} seti={setIndex}  setSelectedArray={setSelecteArray}></ImageCollage>
                }
                {(filter === "None" || filter === "Demolition" || filter === "Construction") &&
                    <ImageCollage collection={DemoArray} Description="Demolition Work" className="b3" clicked = {setClicked} setS={setSelected} seti={setIndex}  setSelectedArray={setSelecteArray}></ImageCollage>
                }
            </div>
            </>
            }
            {clicked&&
            <ImageCollage  collection={selectedArray} Description={"Completed Projects"} className="a1"  clicked = {setClicked} isclicked={true} selected = {selected} index={index}></ImageCollage>
            }
        </div>
    )
}

export default HomeGallery;


