
import LiftingTruses from "../../Data/ConstructionPhotos/framing1.jpg"
import { RedBrickHouseCompleteOutsideCropped } from "../../Data/HomeGalleryPhotos/CompletedProjects/CompletedProjects";
import './ServiceTwoStyle.css'
import MovingQuote from "../ServiceOne/MovingQuote";

const quotes=["Brave",'Bold',"Beautiful"]
function ServiceTwo(params) {

    return (
        <div className="BannerRight">
            <div className="BannerTexts2">
                <MovingQuote quotes={quotes}></MovingQuote>
                <a onClick={() => params.parentCallBack("/HousePlans")}>View Our House Plans</a>
            </div>
            <img className="" src={RedBrickHouseCompleteOutsideCropped} alt="not available"></img>
        </div>
    );
}

export default ServiceTwo;