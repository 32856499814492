import { useState } from 'react';
import './BidStyle.css';
import { bids } from './BidData'
import { nothing } from '../Data/Misc/Misc';

function Bid() {
    const [Bids, setBids] = useState(bids);
    const [currentBid, setCurrentBid] = useState("");

    return (
        <div className="Bids">
            {Bids.length > 0 ?
                <>
                    <div>
                        <h1>
                            Place a bid to work with us!
                        </h1>
                    </div>
                    {currentBid === "" &&
                        <table>
                            <thead>
                                <tr>
                                    <th>Bid Name</th>
                                    <th>Description</th>
                                    <th>Start Date</th>
                                    <th>Due Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Bids.map((bid, IDX) => (
                                    <tr key={IDX}>
                                        <td>{bid.Name}</td>
                                        <td>{bid.Description}</td>
                                        <td>{bid.StartDate}</td>
                                        <td>{bid.DueDate}</td>
                                        <td>
                                            <button onClick={() => setCurrentBid(bid)}>
                                                View Bid
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                    {currentBid !== "" &&
                        <div>
                            <div>
                                <h1>{currentBid.Name}</h1>
                            </div>
                            <div className="bidButtons">
                                <button onClick={() => setCurrentBid("")}>Back to Bids</button>
                                <a href={`mailto:todd@toddalder.com? &subject=Placing%20a%20bid%20on%20${currentBid.Name} &body=${currentBid.Name}%0AYes I want to Bid`}><button>Place Bid</button></a>
                            </div>
                            <div>
                                <iframe src={currentBid.PDF} title="test" />
                            </div>
                        </div>}
                </> :
                <div className='container'>
                    <img src={nothing} alt="Girl in a jacket" width="100%"></img>
                    <div className='none centered'><h1>We currently don't have any Projects to bid on. If you want to contact us feel free to email us at <a id="nofindprojects" href={`mailto:todd@toddalder.com? &subject=Placing%20a%20bid%20on%20${currentBid.Name} &body=${currentBid.Name}%0AYes I want to Bid`}>todd@toddalder.com</a></h1></div>
                </div>
            }
        </div>
    );
};

export default Bid;