import './ToddFooterStyle.css'

const contactInfo = {
    "Phone": "435 - 283 - 8612",
    "Fax": "435 - 283 - 5672",
    "Email": "Todd@toddalder.com"
}

function ToddFooter() {
    return (
        <div className="Footer">
            <h1>Tel: <a href={"tel:" + contactInfo.Phone} id="Phone">{contactInfo.Phone}</a></h1>
            <h1>Fax: {contactInfo.Fax}</h1>
            <a href={"mailto:" + contactInfo.Email}>Email: {contactInfo.Email}</a>
            <p>Copyright © Todd Alder Construction 2007.</p>
        </div>
    );
}

export default ToddFooter;
