import {ChristensenPermit, JeffMcfarlane, lot106, SaratogaPumpHouse,TomBaileyHouse,
    SteveBunchBrighton, ShadPrior, SalinaWastewater, SalinaWasteWaterBook1,
    SalinaWasteWaterBook2, SanpeteFairStruct, SanpeteFairDrawing, SanpeteFairCalc} from './BidPDF/LoadBids'

const bids = [
    {
        "Name": "Christensen Permit",
        "DueDate": "",
        "StartDate": "",
        "Description": "CHRISTENSEN RESIDENCE Spring City, UT.",
        "PDF": ChristensenPermit
    },
    {
        "Name": "Jeff Mcfarlane",
        "DueDate": "",
        "StartDate": "",
        "Description": "Jeff Mcfarlane Residence",
        "PDF": JeffMcfarlane
    },
    {
        "Name": "Lot 107",
        "DueDate": "",
        "StartDate": "",
        "Description": "Lot 107 unfinished basement Birch",
        "PDF": lot106
    },
    {
        "Name": "Saratoga Pump House",
        "DueDate": "",
        "StartDate": "",
        "Description": "SARATOGA SPRINGS CITY DRINKING WATER BOOSTER #8 VOLUME 2 - DRAWINGS OCTOBER 202",
        "PDF": SaratogaPumpHouse
    },
    {
        "Name": "Tom Bailey House",
        "DueDate": "",
        "StartDate": "",
        "Description": "Tom Bailey House Ephraim.",
        "PDF": TomBaileyHouse
    },
    {
        "Name": "Steve Bunch - Brighton 2197",
        "DueDate": "",
        "StartDate": "",
        "Description": "Steve Bunch House.",
        "PDF": SteveBunchBrighton
    },
    {
        "Name": "Shad Prior",
        "DueDate": "",
        "StartDate": "",
        "Description": "Shad Prior House. The new Prior residence.",
        "PDF": ShadPrior
    },
    {
        "Name": "Salina Waste Water Bid Set",
        "DueDate": "",
        "StartDate": "",
        "Description": "Salina Waste Water",
        "PDF": SalinaWastewater
    },
    {
        "Name": "Salina Waste Water Book1",
        "DueDate": "",
        "StartDate": "",
        "Description": "Salina Waste Water Book1",
        "PDF": SalinaWasteWaterBook1
    },
    {
        "Name": "Salina Waste Water Book2",
        "DueDate": "",
        "StartDate": "",
        "Description": "Salina Waste Water Book2",
        "PDF": SalinaWasteWaterBook2
    },
    {
        "Name": "Sanpete County Fair Structural",
        "DueDate": "",
        "StartDate": "",
        "Description": "Sanpete County Fair Drawings Structural",
        "PDF": SanpeteFairStruct
    },
    {
        "Name": "Sanpete County Fair Drawing",
        "DueDate": "",
        "StartDate": "",
        "Description": "Sanpete County Fair Drawings",
        "PDF": SanpeteFairDrawing
    },
    {
        "Name": "Sanpete County Fair Calculations",
        "DueDate": "",
        "StartDate": "",
        "Description": "Sanpete County Fair Calculations",
        "PDF": SanpeteFairCalc
    }
]

export { bids }