
import ServiceOne from './ServiceOne/ServiceOne';
import ServiceTwo from './ServiceTwo/ServiceTwo';
import classes from './Home.module.scss';
import BidsBanner from './Bids/BidsBanner'

function Home(params){



    return(
        <div >
            <ServiceOne parentCallBack={params.parentCallBack}/>
            <BidsBanner parentCallBack={params.parentCallBack}></BidsBanner>
            <ServiceTwo parentCallBack={params.parentCallBack}/>
        </div>
    );
}

export default Home;