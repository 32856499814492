import './ServiceOneStyle.css'
import LiftingTruses from "../../Data/ConstructionPhotos/Lifting Trusses.jpg"
import { BailyFarmsOfficeCropped } from '../../Data/HomeGalleryPhotos/CompletedProjects/CompletedProjects';
import quotes from './quotes.jsx';
import MovingQuote from './MovingQuote';
function ServiceOne(params) {
    return (
        <div className="BannerLeft">
            <img src={BailyFarmsOfficeCropped} alt="not available"></img>
            
            <div className="BannerTexts1">
                <a onClick={() => params.parentCallBack("/ContactUs")}>Contact Us Now!</a>
                <MovingQuote quotes={quotes}></MovingQuote>
            </div>
        </div>
    );
}

export default ServiceOne;